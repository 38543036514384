<template>
  <div>
    <!-- <van-nav-bar right-text="返回"
                 @click-right="onClickRight"> -->
    <van-nav-bar>
      <template #left>
        <van-image radius="18px"
                   height="40px"
                   width="40px"
                   @click="txClick"
                   src="https://image-1302243118.cos.ap-beijing.myqcloud.com/img/toux.jpg"
                   />                                     
      </template>
      <template #title>
        <span style="font-weight:600;color:#0af">面试宝典-微信端</span>
      </template>
    </van-nav-bar>

    <div style="background:#fff"
         align="center">
      <van-image fit="scale-down"
                 src="https://image-1302243118.cos.ap-beijing.myqcloud.com/interview/books/Go_8001611039611515.gif">
        <template v-slot:loading>
          <van-loading type="spinner"
                       size="20"
                       color="#1989fa">加载中...</van-loading>
        </template>
      </van-image>
    </div>

    <van-notice-bar left-icon="volume-o"
                    text="标记红点为待开放栏目"
                    background="rgb(0 170 255 / 22%)" />
    <van-grid clickable
              :column-num="2">
      <van-grid-item icon="hot-o"
                     text="Golang"
                     to="/golang"
                     badge="560" />

      <van-grid-item icon="smile-o"
                     text="数据结构与算法"
                     to="/algorithm" />

      <van-grid-item icon="points"
                     text="数据库"
                     to="/database" />

      <van-grid-item icon="qr"
                     text="操作系统"
                     to="/os" />

      <van-grid-item icon="shield-o"
                     text="网络"
                     to="/network" />

      <van-grid-item icon="cluster-o"
                     text="系统设计"
                     to="/architecture" />

      <van-grid-item icon="certificate"
                     text="工具"
                     to="/tools" />

      <van-grid-item icon="bulb-o"
                     text="通用能力"
                     @click="commonToast"
                     dot />
    </van-grid>
  </div>
</template>

<script>
import { Image as VanImage, Toast, NoticeBar, NavBar, Grid, GridItem,Loading } from 'vant';

export default {
  components: {
    [VanImage.name]: VanImage,
    [Toast.name]: Toast,
    [NoticeBar.name]: NoticeBar,
    [NavBar.name]: NavBar,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [Loading.name]:Loading
  },
  data () {
    return {

    };
  },
  methods: {
    commonToast () {
      Toast("题库整理中，敬请期待！")
    },
    onClickRight () {
     this.$router.push('home');
    },
    //点击头像进入服务页
    txClick () {
      this.$router.push('user');
    }
  },
};
</script>

<style lang="less">
</style>
